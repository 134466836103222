<template>
  <div>
    <modal-edit-prices
      v-if="showEditPrices"
      :channelId="selectedChannelId"
      :showModal="showEditPrices"
      :onClickCancel="hideModal"
    />

    <ui-section-header>
      <template v-slot:title>Prices</template>
    </ui-section-header>

    <div class="columns">
      <div v-for="channel in channels" :key="channel.ChannelId" class="column">
        <div class="level">
          <div class="level-left has-text-weight-bold">
            {{ channel.ChannelName }}
          </div>
          <div class="level-right">
            <a @click="setShowEditPrices(channel.ChannelId)" class="has-icon">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'edit']" />
              </span>
              <span>Edit</span>
            </a>
          </div>
        </div>

        <message
          v-if="getChannelPrices(channel.ChannelId).length === 0"
          :message="'No price added'"
        />

        <table
          v-if="getChannelPrices(channel.ChannelId).length > 0"
          class="table is-fullwidth is-striped"
        >
          <thead>
            <tr>
              <th>Valid from</th>
              <th class="has-text-right">Max pp</th>
              <th class="has-text-right">Max total</th>
              <th class="has-text-right">Type</th>
              <th class="has-text-right">VAT</th>
              <th class="has-text-right">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(price, index) in getChannelPrices(channel.ChannelId)"
              :key="index"
            >
              <td>
                {{ $options.filters.dateObjectIsoDateString(price.ValidFrom) }}
              </td>
              <td class="has-text-right">
                <span v-if="price.MaxPP > 0">{{
                  price.MaxPP | toCurrency
                }}</span>
                <span v-if="price.MaxPP === 0">
                  <font-awesome-icon
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  />
                </span>
              </td>
              <td class="has-text-right">
                <span v-if="price.MaxTotal > 0">{{
                  price.MaxTotal | toCurrency
                }}</span>
                <span v-if="price.MaxTotal === 0">
                  <font-awesome-icon
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  />
                </span>
              </td>
              <td class="has-text-right">{{ price.PriceType }}</td>
              <td class="has-text-right">{{ price.TaxPercentage }}%</td>
              <td class="has-text-right">{{ price.Price | toCurrency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const Message = () => import('@/components/UI/Message')
const ModalEditOptionPrices = () =>
  import('@/components/Options/ModalEditOptionPrices')

export default {
  components: {
    message: Message,
    'modal-edit-prices': ModalEditOptionPrices,
  },

  props: {},

  data() {
    return {
      selectedChannelId: 0,
      showEditPrices: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),

    channels() {
      let channels = []
      if (this.locationState) {
        channels = this.locationState.Channels.filter(
          (c) => c.ChannelId === this.channel.Id
        )
      } else {
        if (this.channel) {
          channels.push({
            ChannelId: this.channel.Id,
            ChannelName: this.channel.Name,
          })
        }
      }

      return channels
    },
  },

  created() {},

  methods: {
    getChannelPrices(channelId) {
      return this.option.Prices.filter((p) => p.ChannelId === channelId)
    },

    setShowEditPrices(channelId) {
      this.selectedChannelId = channelId
      this.showEditPrices = true
    },

    hideModal() {
      this.selectedChannelId = 0
      this.showEditPrices = false
    },
  },
}
</script>

<style></style>
